<template>
  <v-main>
    <v-col cols="12" class="mt-3">
      <h1 class="mb-0">{{ $t("resetPassword") }}</h1>
      <p>{{ $t("enterEmailWillSendEmailResetPassword") }}</p>
      <v-text-field
        :label="$t('emailAddress')"
        required
        :error-count="errors.length"
        :error-messages="errors"
        :hide-details="errors.length === 0"
        color="#59D79D"
        v-model="email"
      ></v-text-field>
      <span class="dark-color d-inline-block line-height-2"
        >{{ $t("alreadyHaveAccount") }}?
        <router-link :to="{ name: 'auth1.sign-in1' }" class="green-prosk">{{
          $t("logIn")
        }}</router-link>
      </span>
      <v-col cols="12">
        <div class="float-end">
          <save-button :save="onSubmit" :loading="loading" :message="$t('resetPassword')"/>
        </div>
      </v-col>
    </v-col>
  </v-main>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';

export default {
  name: 'RecoverPassword',
  components: { SaveButton },
  mixins: [errorDialog],
  data () {
    return {
      errors: [],
      loading: false,
      email: null
    };
  },
  methods: {
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onSubmit () {
      this.errors = [];
      if (!this.email) {
        this.errors.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.errors.push(this.$t('enterValidEmail'));
      }
      if (this.email && this.validEmail(this.email)) {
        const url = `${process.env.VUE_APP_BASE_URL}/api/resetPassword`;
        const data = {
          email: this.email
        };
        this.loading = true;
        axios
          .post(url, data)
          .then(() => {
            this.loading = false;
            this.email = null;
            this.$swal.fire({
              title: this.$t('awesome'),
              text: this.$t('emailSentCheckInbox'),
              confirmButtonColor: '#19AD93'
            });
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
